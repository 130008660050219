import { HiOutlineSearch } from "react-icons/hi";
import React from "react";

interface Props {
  searchValue: string;
  setSearchValue: Function;
  handleSearch: Function;
}

const SearchBox = ({ searchValue, setSearchValue, handleSearch }: Props) => {
  return (
    <form
      className="flex items-center px-2 py-1.5 mr-4 text-base bg-white border rounded"
      onSubmit={() => handleSearch()}
    >
      <input
        type="text"
        placeholder="Search"
        className="bg-transparent outline-none"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <button type="submit">
        <HiOutlineSearch className="icon-md" />
      </button>
      
    </form>
  );
};

export default SearchBox;
