import { useField } from "formik";
import ErrorBox from "./ErrorBox";
import React from "react";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  type: string;
  id: string;
  name: string;
  autoFocus?: boolean;
  optional?: boolean;
  inline?: boolean;
  readonly?: boolean;
  classes?: string;
  step?: string;
  placeholder?: string;
}

const Input = ({
  label,
  type,
  id,
  name,
  classes,
  readonly = false,
  autoFocus = false,
  optional = false,
  inline = false,
  step = "1",
  placeholder,
  ...props
}: Props) => {
  //   field -> { name: string, value: string, onChange: () => {}, onBlur: () => {} }
  //   meta -> { touched: boolean, error: string, ... }
  const [field, meta] = useField({ name, type });

  return (
    <div className={`flex flex-col w-full h-16 mb-6 ${classes}`}>
      <div
        className={`form-group flex ${
          inline ? " flex-row items-center" : "flex-col justify-center"
        }`}
      >
        <label
          className={`font-semibold text-sm ${inline ? "mr-4 w-min" : "mb-2"}`}
          htmlFor={id}
        >
          {label}{" "}
          {optional && <span className="optional text-slate-400">(O)</span>}
        </label>

        {type === "number" ? (
          <input
            disabled={readonly}
            readOnly={readonly}
            autoFocus={autoFocus}
            className={`${
              meta.touched && meta.error
                ? "border-red-400 "
                : "border-slate-200 focus:border-primary"
            }   placeholder-transparent  disabled:opacity-80 disabled:cursor-not-allowed`}
            {...field}
            {...props}
            id={id}
            type={type}
            min="0"
            step={step}
            {...props}
          />
        ) : (
          <input
            disabled={readonly}
            readOnly={readonly}
            autoFocus={autoFocus}
            placeholder={placeholder}
            className={`${
              meta.touched && meta.error
                ? "border-red-400 "
                : "border-slate-200 focus:border-primary"
            }   placeholder-transparent  disabled:opacity-80 disabled:cursor-not-allowed`}
            {...field}
            {...props}
            id={id}
            type={type}
          />
        )}
      </div>
      {meta.touched && meta.error && <ErrorBox msg={meta.error} />}
    </div>
  );
};

export default Input;
