import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  APPROVE_ARTISAN_MODAL,
  EDIT_ALL_ARTISAN_MODAL,
} from "../../types/constants";

interface UserObj {
  id: string;
  gstin: string;
  name: string;
  companyName: string;
  createdAt: string;
}

const AllArtisan = () => {
  const ALL_USERS_COLUMNS = [
    {
      Header: "Id",
      accessor: "artisan_id",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Company Email",
      accessor: "company_email",
    },
    {
      Header: "Company Mobile",
      accessor: "company_mobile",
    },
    {
      Header: "KYC Image",
      accessor: "artisan_kyc_doc_url",
      Cell: ({ value }: any) => {
        return (
          <a
            href={value}
            className="text-green-400"
            target="_blank"
            rel="noreferrer"
          >
            Open Image
          </a>
        );
        // return <img src={value} className="h-44" />
      },
    },
    {
      Header: "Photo Site",
      accessor: "site_image_url",
      Cell: ({ value }: any) => {
        return (
          <a
            href={value}
            className="text-green-400"
            target="_blank"
            rel="noreferrer"
          >
            Open Image
          </a>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }: any) => {
        return value === "approved" ? (
          "Yes"
        ) : (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: APPROVE_ARTISAN_MODAL,
                  modalTitle: "Approve Artisan",
                  modalProps: {
                    data: row.original,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            Approve Artisan
          </button>
        );
      },
    },

    //TODO: IMP

    {
      Header: "Edit",
      accessor: "edit",
      Cell: ({ value, row }: any) => {
        console.table(row.original);
        return (
          <button
            onClick={() => {
              dispatch(
                showModal({
                  modalType: EDIT_ALL_ARTISAN_MODAL,
                  modalTitle: "Edit Artisan",
                  modalProps: {
                    artisanId: row?.original.id,
                    name: row?.original.name,
                    address: row?.original.address,
                    companyEmail: row?.original.company_email,
                    artisanKycDocUrl: row?.original.artisan_kyc_doc_url,
                    companyMobile: row?.original.company_mobile,
                    siteImageUrl: row?.original.site_image_url,
                    addressId: row?.original.addressId,
                  },
                })
              );
            }}
            className="bg-green-600 text-white px-2 py-3"
          >
            Edit Artisan
          </button>
        );
      },
    },

    //TODO: IMP
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [, setSelectedRows] = useState([]);

  let component = null;

  const getArtisans = async () => {
    const { data } = await axiosInstance.get(`/artisan/all?status=pending`);
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getArtisans", pageIndex, cPageSize, cSortBy, desc, q], getArtisans);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">
        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        {/* <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_CATEGORY_MODAL,
                  modalTitle: "Add a User",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div> */}
      </header>

      {component !== null && component}
    </div>
  );
};

export default AllArtisan;
