import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface BiomassConfigObj {
  artisanIdAssigned: string;
}

type EditBiomassConfigModalProps = {
  biomassConfigId: number;
  artisanIdAssigned: string;
};

const EditBiomassConfigModal: React.FC<EditBiomassConfigModalProps> = ({
  biomassConfigId,
  artisanIdAssigned,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: BiomassConfigObj = {
    artisanIdAssigned: artisanIdAssigned,
  };

  const validationSchema = Yup.object({
    artisanIdAssigned: Yup.string().required("Artisan ID is required"),
  });

  const handleSubmit = useCallback(
    async (biomass_config: BiomassConfigObj) => {
      setIsSubmitting(true);
      console.log("Biomass Composition ID:", biomassConfigId);
      console.log("Payload being sent:", {
        artisanIdAssigned: biomass_config.artisanIdAssigned,
      }); // Log the payload

      try {
        const result = await axiosInstance.patch(
          `/biomass_composition/${biomassConfigId}`, // Use the specified path for PATCH
          {
            artisanIdAssigned: biomass_config.artisanIdAssigned,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log(result);

        queryClient.invalidateQueries(["getAllBiomassComposition"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Biomass configuration updated successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        // Handle different error cases
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          dispatch(
            addToast({
              kind: ERROR,
              msg: msg || "An error occurred while processing your request.",
            })
          );
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Network error. Please try again later.",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [biomassConfigId, queryClient, dispatch]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, handleChange }) => (
        <Form
          className="px-6 py-4 mt-2"
          style={{
            minWidth: "360px",
            maxWidth: "760px",
          }}
        >
          <h2 className="text-xl font-semibold">Update Artisan ID</h2>

          <Input
            label="Artisan ID Assigned"
            id="artisanIdAssigned"
            name="artisanIdAssigned"
            type="text"
            value={values.artisanIdAssigned}
            onChange={handleChange}
          />

          <div className="buttons flex items-center w-full justify-center my-4">
            <SubmitBtn
              text="Save"
              isSubmitting={isSubmitting}
              classes="text-sm"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditBiomassConfigModal;
