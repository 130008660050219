import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../axiosInstance";
import SearchBox from "../components/SearchBox/SearchBox";
import TableInstance from "../components/Table/TableInstance";
import { showModal } from "../redux/features/modalSlice";
import {
    EDIT_FPO_MODAL,
    VIEW_FPO_MODAL,
    FPO_ONBOARDING_FORM,
} from "../types/constants";
import { NavLink, Outlet, useParams } from "react-router-dom";
import InfoCard from "../components/Accordian-Cards/InfoCard";

interface FPOObj {
    fpo_id: string; // FPO ID
    fpo_name: string; // Name
    fpo_number: string; // Contact
    fpo_email: string; // Email
    cin: string; // CIN
    gst_number: string; // GST Number
    createdAt: string; // Created at
    is_active: boolean; // Active status
    password: string;
}

interface FPODetailsObj {
    total_users: number;
    pending_approvals: number;
    total_biomass_batches: number;
    password: string;
}

function FPOLayout() {
    const { id } = useParams();

    const ALL_USERS_COLUMNS = [
        {
            Header: "Sr. No",
            accessor: "serialNumber", // Serial number column
            Cell: ({ row }: any) => {
                return row.index + 1; // Serial number starting from 1
            },
        },
        {
            Header: "FPO ID",
            accessor: "fpo_id",
        },
        {
            Header: "Name",
            accessor: "fpo_name",
        },
        {
            Header: "Email",
            accessor: "fpo_email",
        },
        {
            Header: "Contact",
            accessor: "fpo_number",
        },
        {
            Header: "CIN",
            accessor: "cin",
        },
        {
            Header: "GST Number",
            accessor: "gst_number",
        },
        {
            Header: "Password",
            accessor: "password",
        },
        // {
        //   Header: "Created at",
        //   accessor: "createdAt",
        //   Cell: ({ value }: any) => {
        //     const dateValue = new Date(value);
        //     return !isNaN(dateValue.getTime())
        //       ? dateValue.toLocaleString()
        //       : "Invalid Date";
        //   },
        // },



        // // View button column
        // {
        //   Header: "View",
        //   accessor: "view",
        //   Cell: ({ row }: any) => {
        //     return (
        //       <>
        //         <NavLink
        //          to={`details/${row?.original.fpo_id}`}
        //          className="bg-green-600 text-white px-2 py-3"
        //         >
        //           View Details
        //         </NavLink>
        //       </>
        //       // <button
        //       //   onClick={() => {
        //       //     console.log(row.original);
        //       //     dispatch(
        //       //       showModal({
        //       //         modalType: VIEW_FPO_MODAL,
        //       //         modalTitle: "View Details",
        //       //         modalProps: {
        //       //           fpo_name: row?.original.fpo_name,
        //       //           fpo_number: row?.original.fpo_number,
        //       //           fpo_email: row?.original.fpo_email,
        //       //           cin: row?.original.cin,
        //       //           gst_number: row?.original.gst_number,
        //       //           fpo_id: row?.original.fpo_id,
        //       //           addressId: row?.original.addressId,
        //       //         },
        //       //       })
        //       //     );
        //       //   }}
        //       //   className="bg-green-600 text-white px-2 py-3"
        //       // >
        //       //   View Details
        //       // </button>
        //     );
        //   },
        // },

        // // Edit button column
        // {
        //   Header: "Edit",
        //   accessor: "edit",
        //   Cell: ({ row }: any) => {
        //     return (
        //       <button
        //         onClick={() => {
        //           console.log(row.original);
        //           dispatch(
        //             showModal({
        //               modalType: EDIT_FPO_MODAL,
        //               modalTitle: "Edit Details",
        //               modalProps: {
        //                 fpo_name: row?.original.fpo_name,
        //                 fpo_number: row?.original.fpo_number,
        //                 fpo_email: row?.original.fpo_email,
        //                 cin: row?.original.cin,
        //                 gst_number: row?.original.gst_number,
        //                 fpo_id: row?.original.fpo_id,
        //                 // addressId: row?.original.addressId,
        //               },
        //             })
        //           );
        //         }}
        //         className="bg-green-600 text-white px-2 py-3"
        //       >
        //         Edit Details
        //       </button>
        //     );
        //   },
        // },

        // // Active status with toggle switch
        // {
        //   Header: "Active",
        //   accessor: "is_active",
        //   Cell: ({ row }: any) => {
        //     const [active, setActive] = useState(row.original.is_active);

        //     const toggleActiveStatus = async () => {
        //       try {
        //         const updatedStatus = !active;
        //         setActive(updatedStatus);

        //         // API call to update the active status in the backend
        //         const response = await axiosInstance.patch(`/fpo/update/`, {
        //           is_active: updatedStatus,
        //           fpo_id: row?.original.fpo_id,
        //         });
        //         if (response.status === 200) {
        //         }
        //         queryClient.invalidateQueries(["getAllFpos"]);

        //         console.log(
        //           `FPO ID: ${row.original.fpo_id} status updated to:`,
        //           updatedStatus
        //         );
        //       } catch (error) {
        //         console.error("Failed to update active status:", error);
        //       }
        //     };

        //     return (
        //       <div
        //         className={`relative inline-block w-14 h-7 rounded-full ${
        //           active ? "bg-green-500" : "bg-gray-400"
        //         } cursor-pointer`}
        //         onClick={toggleActiveStatus}
        //       >
        //         {/* Switch button */}
        //         <span
        //           className={`absolute left-1 top-1 w-5 h-5 rounded-full bg-white transition-transform ${
        //             active ? "translate-x-7" : "translate-x-0"
        //           }`}
        //         />

        //         {/* "Yes" label on the left side when active */}
        //         <span
        //           className={`absolute left-2 top-1/2 transform -translate-y-1/2 text-xs font-semibold ${
        //             active ? "text-white" : "text-transparent"
        //           }`}
        //         >
        //           Yes
        //         </span>

        //         {/* "No" label on the right side when inactive */}
        //         <span
        //           className={`absolute right-2 top-1/2 transform -translate-y-1/2 text-xs font-semibold ${
        //             active ? "text-transparent" : "text-gray-600"
        //           }`}
        //         >
        //           No
        //         </span>
        //       </div>
        //     );
        //   },
        // },
    ];

    const dispatch = useDispatch();

    const [cPageSize, cSetPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [cSortBy, cSetSortBy] = useState(null);
    const [desc, setDesc] = useState(true);
    const queryClient = useQueryClient();

    const [q, setQ] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);

    let component = null;

    const getAllFpos = async ({ queryKey }: any) => {
        console.log(`Fetching FPO data from '/fpo/all'`);

        const { data } = await axiosInstance.get(`/fpo/all`);
        const fpoData = await axiosInstance.get(`/fpo/detail/${id}`);

        let requiredData = [];

        for (let item of data.data) {
            if (item.fpo_id === id) {
                requiredData.push(item);
                break;
            }
        }
        const pass = fpoData.data.data.password;
        requiredData[0].password = pass;

        // console.log("FPO data fetched successfully:", data);
        return requiredData;
        // return data.data;
    };

    const { data, error, isLoading } = useQuery<
        FPOObj[] | undefined,
        any,
        FPOObj[],
        any[]
    >(["getAllFpos", pageIndex, cPageSize, cSortBy, desc, q], getAllFpos);

    if (error) {
        component = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        component = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        component = (
            <TableInstance
                tableData={data || []}
                columnName={ALL_USERS_COLUMNS}
                cPageSize={cPageSize}
                cSetPageSize={cSetPageSize}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageCount={-1}
                cSortBy={cSortBy}
                cSetSortBy={cSetSortBy}
                desc={desc}
                setDesc={setDesc}
                q={q}
                setQ={setQ}
                setSelectedRows={setSelectedRows}
                selectRow={true}
            />
        );
    }






    // For cards
    let CardComponent = null;

    const getFpoDetails = async ({ queryKey }: any) => {
        console.log(`Fetching FPO data from '/fpo/detail/${id}'`);

        const fpoData = await axiosInstance.get(`/fpo/detail/${id}`);

        return fpoData.data.data;
    };

    const { data:fpoData, error:fpoError, isLoading:fpoIsLoading } = useQuery<
        FPODetailsObj| undefined,
        any,
        FPODetailsObj,
        any[]
    >(["getFpoDetails", pageIndex, cPageSize, cSortBy, desc, q], getFpoDetails);

    if (error) {
        CardComponent = (
            <p className="mt-6 ml-4 text-center">
                An error has occurred: {error.message}
            </p>
        );
    } else if (isLoading) {
        CardComponent = <p className="mt-6 ml-4 text-center">Loading...</p>;
    } else {
        console.log(fpoData);
        CardComponent = (
            <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-7">
                    <InfoCard title="Total Users" value={fpoData?.total_users} />
                    <InfoCard title="Pending Approvals" value={fpoData?.pending_approvals} />
                    <InfoCard title="Total Biomass Batches" value={fpoData?.total_biomass_batches} />
                </div>
            </>
        )
    }
    


    return (
        <div className="users h-full w-full">


            {/* fpo detaills */}
            <div className="all-users">
                <header className="flex items-center justify-between mb-8">
                    <div className="left">
                        <SearchBox
                            searchValue={q}
                            setSearchValue={setQ}
                            handleSearch={() => { }}
                        />
                    </div>

                    {/* <div className="right">
                        <button
                            className="btn-primary flex items-center"
                            onClick={() => {
                                dispatch(
                                    showModal({
                                        modalType: FPO_ONBOARDING_FORM,
                                        modalTitle: "Add FPO",
                                    })
                                );
                            }}
                        >
                            <span className="mr-1">Add</span> <MdAdd size={17} />
                        </button>
                    </div> */}
                </header>

                {component !== null && component}

                {CardComponent !== null && CardComponent}
            </div>



            {/* Navigation for fpo details */}
            <nav className="users-nav my-12 noselect">
                <ul className="flex gap-x-6 items-center">
                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to=""
                        >
                            Seller
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="buyers"
                        >
                            Buyer
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            className={({ isActive }) =>
                                `flex-1 text-lg rounded font-semibold px-2 py-1 shadow ${isActive ? "bg-primary text-white" : "bg-white"
                                }`
                            }
                            end
                            to="artisans"
                        >
                            Artisan
                        </NavLink>
                    </li>
                </ul>
            </nav>


            <div className="content">
                <Outlet />
            </div>
        </div>
    )
}

export default FPOLayout