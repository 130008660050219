import { Form, Formik } from "formik";
import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import axiosInstance from "../../axiosInstance";
import { hideModal } from "../../redux/features/modalSlice";
import { addToast } from "../../redux/features/toastSlice";
import { ERROR, SUCCESS } from "../../types/constants";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";

interface MeasuringVesselObj {
  // vesselName: string;
  // manufacturerName: string;
  // vesselType: string;
  volume: string;
  // vesselImage: string;
}

type EditMeasuringVesselModalProps = {
  measuringVesselId: number;
  // vesselName: string;
  // manufacturerName: string;
  // vesselType: string;
  volume: string;
  // vesselImage: string;
  // addressId: string;
};

const EditMeasuringVesselModal: React.FC<EditMeasuringVesselModalProps> = ({
  measuringVesselId,
  // vesselName,
  // manufacturerName,
  // vesselType,
  volume,
  // vesselImage,
  // addressId,
}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues: MeasuringVesselObj = {
    // vesselName: vesselName,
    // manufacturerName: manufacturerName,
    // vesselType: vesselType,
    volume: volume,
    // vesselImage: vesselImage,
  };

  const validationSchema = Yup.object({
    // vesselName: Yup.string().required("Vessel Name is required"),
    // manufacturerName: Yup.string().required("Manufacturer Name is required"),
    // vesselType: Yup.string().required("Vessel Type is required"),
    volume: Yup.string().required("Volume is required"),
    // vesselImage: Yup.string().required("Vessel Image is required"),
  });

  const handleSubmit = useCallback(
    async (measuringVessel: MeasuringVesselObj) => {
      setIsSubmitting(true);
      try {
        const result = await axiosInstance.patch(
          `/measuring_vessel/${measuringVesselId}`,
          {
            // vesselName: measuringVessel.vesselName,
            // manufacturerName: measuringVessel.manufacturerName,
            // vesselType: measuringVessel.vesselType,
            volume: measuringVessel.volume,
            // vesselImage: measuringVessel.vesselImage,
            // addresses: [
            //   {
            //     addressId: addressId,
            //   },
            // ],
          },
          { headers: { ContentType: "application/json" } }
        );
        console.log(result);

        queryClient.invalidateQueries(["getMeasuringVessels"]);
        dispatch(
          addToast({
            kind: SUCCESS,
            msg: "Measuring Vessel Updated Successfully!",
          })
        );

        dispatch(hideModal());
      } catch (error: any) {
        if (error.response) {
          const response = error.response;
          const { msg } = response.data;

          switch (response.status) {
            case 400:
            case 500:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: msg,
                })
              );
              break;
            default:
              dispatch(
                addToast({
                  kind: ERROR,
                  msg: "Oops, something went wrong",
                })
              );
              break;
          }
        } else if (error.request) {
          dispatch(
            addToast({
              kind: ERROR,
              msg: "Oops, something went wrong",
            })
          );
        } else {
          dispatch(
            addToast({
              kind: ERROR,
              msg: `Error: ${error.message}`,
            })
          );
        }
      }
      setIsSubmitting(false);
    },
    [dispatch, measuringVesselId, queryClient] //addressId
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form
        className="px-6 py-4 mt-2"
        style={{
          minWidth: "360px",
          maxWidth: "760px",
        }}
      >
        {/* <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Name"
            id="vesselName"
            name="vesselName"
            type="text"
          />
        </div> */}

        {/* <div className="flex item-center gap-x-6">
          <Input
            label="Manufacturer Name"
            id="manufacturerName"
            name="manufacturerName"
            type="text"
          />
        </div> */}

        {/* <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Type"
            id="vesselType"
            name="vesselType"
            type="text"
          />
        </div> */}

        <div className="flex item-center gap-x-6">
          <Input label="Volume" id="volume" name="volume" type="text" />
        </div>

        {/* <div className="flex item-center gap-x-6">
          <Input
            label="Vessel Image"
            id="vesselImage"
            name="vesselImage"
            type="text"
          />
        </div> */}

        <div className="buttons flex items-center w-full justify-center my-4">
          <SubmitBtn
            text="Save"
            isSubmitting={isSubmitting}
            classes="text-sm"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default EditMeasuringVesselModal;
