import { GiMedicalPack, GiWheat } from "react-icons/gi";
import { HiOutlineChevronDoubleLeft, HiOutlineMenu } from "react-icons/hi";
import {
  MdCategory,
  MdDashboard,
  MdGroup,
  MdList,
  MdPerson,
  MdOutlineBatchPrediction,
} from "react-icons/md";
import { GiFertilizerBag } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/app";
import { hideSidebar, showSidebar } from "../../redux/features/sidebarSlice";
import Logo from "../Logo/Logo";
import SideBarLink from "./SidebarLink";
import { useState } from "react";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { show } = useSelector((state: RootState) => state.sidebar);

  const [role, setRole] = useState(localStorage.getItem('role'))

  return (
    <aside
      className={`sidebar z-20 shadow-xl fixed left-0 top-0 bottom-0 overflow-x-hidden box-border ${show ? "w-60" : "w-20"
        } bg-neutral-800 h-screen flex flex-col text-white transition-all`}
    >
      <header
        className={` ${show ? "justify-between" : "justify-center"
          } flex  items-center px-4 py-5 mb-2 noselect`}
      >
        {
          <div className={`${show ? "opacity-100" : "opacity-0 hidden"}`}>
            <Logo />
          </div>
        }

        {show ? (
          <button
            onClick={() => dispatch(hideSidebar())}
            className="text-white"
          >
            <HiOutlineChevronDoubleLeft size={21} />
          </button>
        ) : (
          <button
            className="text-white"
            onClick={() => dispatch(showSidebar())}
          >
            <HiOutlineMenu size={21} />
          </button>
        )}
      </header>

      <nav className="sidebar-links" id="sidebar">
        <ul>
          <SideBarLink
            to="/"
            Icon={MdDashboard}
            text="Dashboard"
            smallText="Dashboard"
            key="Dashboard"
          />

          <SideBarLink
            to="/users"
            list={[
              "/users",
              "/users/buyers",
              "/users/fpo",
              "/users/fpo/details",
              "/users/artisans",
              "/users/artisans/pending",
              "/users/artisans/approved",
            ]}
            Icon={MdGroup}
            text="Users"
            smallText="Users"
            key="Users"
          />

          <SideBarLink
            to="/products"
            list={["/products", "/products/deleted"]}
            Icon={MdList}
            text="Products"
            smallText="Products"
            key="Products"
          />

          <SideBarLink
            to="/categories"
            list={["/categories"]}
            Icon={MdCategory}
            text="Categories"
            smallText="Categories"
            key="categories"
          />

          <SideBarLink
            to="/crops"
            list={["/crops"]}
            Icon={GiWheat}
            text="Crops"
            smallText="Crops"
            key="crops"
          />


          {role === 'admin' && (<SideBarLink
            to="/fertilisers"
            list={["/fertilisers"]}
            Icon={GiMedicalPack}
            text="Ferilisers"
            smallText="Ferilisers"
            key="Ferilisers"
          />)}

          {role === 'admin' && (<SideBarLink
            to="/fertilisers/comb"
            list={["/fertilisers/comb"]}
            Icon={GiFertilizerBag}
            text="Ferilisers Combos"
            smallText="Ferilisers Combos"
            key="Ferilisers Combos"
          />)}

          {/* <SideBarLink
            to="/artisans"
            list={["/artisans"]}
            Icon={MdPerson}
            text="All Artisans"
            smallText="All Artisans"
            key="All Artisans"
          /> */}

          <SideBarLink
            to="/biomass-batch"
            list={["/biomass-batch"]}
            Icon={MdOutlineBatchPrediction}
            text="BioChar Batches"
            smallText="BioChar Batches"
            key="BioChar Batches"
          />

          {role === 'admin' && (<SideBarLink
            to="/biomass-composition"
            list={["/biomass-composition"]}
            Icon={MdOutlineBatchPrediction}
            text="Biomass Config"
            smallText="Biomass Config"
            key="Biomass Config"
          />)}

          <SideBarLink
            to="/pyrolysis-kiln"
            list={["/pyrolysis-kiln"]}
            Icon={MdOutlineBatchPrediction}
            text="Pyrolysis Kiln"
            smallText="Pyrolysis Kiln"
            key="Pyrolysis Kiln"
          />

          <SideBarLink
            to="/soil-pit"
            list={["/soil-pit"]}
            Icon={MdOutlineBatchPrediction}
            text="Soil Pit"
            smallText="Soil Pit"
            key="Soil Pit"
          />

          {role === 'admin' && (<SideBarLink
            to="/measuring-vessel"
            list={["/measuring-vessel"]}
            Icon={MdOutlineBatchPrediction}
            text="Measuring Vessel"
            smallText="Measuring Vessel"
            key="Measuring Vessel"
          />)}

          {role === 'admin' && (<SideBarLink
            to="/project"
            list={["/project"]}
            Icon={MdOutlineBatchPrediction}
            text="Project"
            smallText="Project"
            key="Project"
          />)}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
