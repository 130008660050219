import { useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import axiosInstance from "../../axiosInstance";
import SearchBox from "../../components/SearchBox/SearchBox";
import TableInstance from "../../components/Table/TableInstance";
import { showModal } from "../../redux/features/modalSlice";
import {
  ASSIGN_BIOMASS_MODAL,
  VIEW_ARTISAN_BATCHES_DETAIL,
  VIEW_ARTISAN_OR_PROJECT_MODAL,
} from "../../types/constants";
import { Option } from "../../types";

interface UserObj {
  id: string;
  gstin: string;
  name: string;
  companyName: string;
  createdAt: string;
}

const AllApprovedArtisan = () => {
  const ALL_USERS_COLUMNS = [
    {
      Header: "Id",
      accessor: "artisan_id",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, row }: any) => {
        return (
          <div
            onClick={() => {
              console.log(row.original.artisan_id);
              dispatch(
                showModal({
                  modalType: VIEW_ARTISAN_BATCHES_DETAIL,
                  modalTitle: "View Batches",
                  modalProps: {
                    id: row.original.artisan_id,
                  },
                })
              );
            }}
            className="cursor-pointer"
          >
            <p>{value}</p>
          </div>
        );
      },
    },

    {
      Header: "Address",
      accessor: "address",
    },
    {
      Header: "Company Email",
      accessor: "company_email",
    },
    {
      Header: "Company Mobile",
      accessor: "company_mobile",
    },
    {
      Header: "KYC Image",
      accessor: "artisan_kyc_doc_url",
      Cell: ({ value }: any) => {
        return (
          <a
            href={value}
            className="text-green-400"
            target="_blank"
            rel="noreferrer"
          >
            Open Image
          </a>
        );
        // return <img src={value} className="h-44" />
      },
    },

    {
      Header: "Photo Site",
      accessor: "site_image_url",
      Cell: ({ value }: any) => {
        return (
          <a
            href={value}
            className="text-green-400"
            target="_blank"
            rel="noreferrer"
          >
            Open Image
          </a>
        );
      },
    },

    {
      Header: "Assign Biomass",
      accessor: "createdAt",
      Cell: ({ value, row }: any) => {
        const biomass_compositions: Option[] = (
          (row?.original?.biomass_compositions as any[]) || []
        ).map((element) => {
          return {
            label: element.biomass_name,
            value: element.biomass_composition_id,
          };
        });

        return (
          <div>
            <button
              onClick={() => {
                dispatch(
                  showModal({
                    modalType: ASSIGN_BIOMASS_MODAL,
                    modalTitle: "Assign Biomass",
                    modalProps: {
                      artisan_id: row.original.artisan_id,
                      assigned_biomass: biomass_compositions,
                    },
                  })
                );
              }}
              className="bg-green-600 text-white px-2 py-3"
            >
              {biomass_compositions.length > 0
                ? "Edit Biomass"
                : "Assign Biomass"}
            </button>
            
          </div>
        );
      },
    },

    {
      Header: "Projects list",
      accessor: "project_id",
      Cell: ({ value, row }: any) => {
        return (
          <button
            className="bg-green-600 text-white px-2 py-3"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: VIEW_ARTISAN_OR_PROJECT_MODAL,
                  modalTitle: "View Projects",
                  modalProps: {
                    isProjectsList: true,
                    id: row.original.artisan_id,
                  },
                })
              );
            }}
          >
            <span className="mr-1">View List</span>
          </button>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value, row }: any) => {
        return value === "approved" ? (
          "Approved"
        ) : (
          <button
            // onClick={() => { approveArtisan(row.original.artisan_id) }}
            className="bg-green-600 text-white px-2 py-3"
          >
            Approve Artisan
          </button>

        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [cPageSize, cSetPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [cSortBy, cSetSortBy] = useState(null);
  const [desc, setDesc] = useState(true);
  const [q, setQ] = useState("");

  const [, setSelectedRows] = useState([]);

  let component = null;

  const getArtisans = async () => {
    const { data } = await axiosInstance.get(`/artisan/all?status=approved`);
    return data?.data;
  };

  const { data, error, isLoading } = useQuery<
    UserObj[] | undefined,
    any,
    UserObj[],
    any[]
  >(["getArtisans", pageIndex, cPageSize, cSortBy, desc, q], getArtisans);

  if (error) {
    component = (
      <p className="mt-6 ml-4 text-center">
        An error has occurred: {error.message}
      </p>
    );
  } else if (isLoading) {
    component = <p className="mt-6 ml-4 text-center">Loading...</p>;
  } else {
    component = (
      <TableInstance
        tableData={data || []}
        columnName={ALL_USERS_COLUMNS}
        cPageSize={cPageSize}
        cSetPageSize={cSetPageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageCount={-1} // do calculation here
        cSortBy={cSortBy}
        cSetSortBy={cSetSortBy}
        desc={desc}
        setDesc={setDesc}
        q={q}
        setQ={setQ}
        setSelectedRows={setSelectedRows}
        selectRow={true}
      />
    );
  }

  return (
    <div className="all-users">
      <header className="flex items-center justify-between mb-8">

        <div className="left">
          <SearchBox
            searchValue={q}
            setSearchValue={setQ}
            handleSearch={() => {}}
          />
        </div>

        {/* <div className="right">
          <button
            className="btn-primary flex items-center"
            onClick={() => {
              dispatch(
                showModal({
                  modalType: ADD_CATEGORY_MODAL,
                  modalTitle: "Add a User",
                })
              );
            }}
          >
            <span className="mr-1">Add</span> <MdAdd size={17} />
          </button>
        </div> */}

      </header>

      {component !== null && component}
    </div>
  );
};

export default AllApprovedArtisan;
