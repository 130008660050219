import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import logo from "../../assets/logo192.png";
import axiosInstance from "../../axiosInstance";
import ErrorBox from "../FormikComponents/ErrorBox";
import Input from "../FormikComponents/Input";
import SubmitBtn from "../FormikComponents/SubmitBtn";
import { loginUser } from "../../redux/features/authSlice";
import { data } from "../../data/dummyGraphData";
import Select from "../FormikComponents/Select";
import { addToast } from "../../redux/features/toastSlice";
import { SUCCESS } from "../../types/constants";
import { useQueryClient } from "react-query";
import { hideModal } from "../../redux/features/modalSlice";

const stateArray = [
  { value: "", label: "Select State" },
  { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  { value: "Assam", label: "Assam" },
  { value: "Bihar", label: "Bihar" },
  { value: "Chhattisgarh", label: "Chhattisgarh" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  { value: "Haryana", label: "Haryana" },
  { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  { value: "Jharkhand", label: "Jharkhand" },
  { value: "Karnataka", label: "Karnataka" },
  { value: "Kerala", label: "Kerala" },
  { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  { value: "Maharashtra", label: "Maharashtra" },
  { value: "Manipur", label: "Manipur" },
  { value: "Meghalaya", label: "Meghalaya" },
  { value: "Mizoram", label: "Mizoram" },
  { value: "Nagaland", label: "Nagaland" },
  { value: "Odisha", label: "Odisha" },
  { value: "Punjab", label: "Punjab" },
  { value: "Rajasthan", label: "Rajasthan" },
  { value: "Sikkim", label: "Sikkim" },
  { value: "Tamil Nadu", label: "Tamil Nadu" },
  { value: "Telangana", label: "Telangana" },
  { value: "Tripura", label: "Tripura" },
  { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  { value: "Uttarakhand", label: "Uttarakhand" },
  { value: "West Bengal", label: "West Bengal" },
];

interface FPOObj {
  fpo_name: string;
  fpo_number: string;
  fpo_email: string;
  password: string;
  address: string;
  street: string;
  city: string;
  state: string;
  pinCode: string;
  cin: string;
  gst_number: string;
}

const FPOOnboardedForm: React.FC = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commonError, setCommonError] = useState("");
  const [showForm, setShowForm] = useState(true);
  const queryClient = useQueryClient();
  const initialValues: FPOObj = {
    fpo_name: "",
    fpo_number: "",
    fpo_email: "",
    password: "",
    address: "",
    street: "",
    city: "",
    state: "",
    pinCode: "",
    cin: "",
    gst_number: "",
  };

  const validationSchema = Yup.object({
    fpo_name: Yup.string().required("FPO Name is required"),
    fpo_number: Yup.string()
      .matches(/^[0-9]+$/, "Invalid. Phone Number must be only digits")
      .length(10, "Phone Number must be exactly 10 digits")
      .required("Phone Number is required"),
    fpo_email: Yup.string()
      .email("Invalid fpo_email address")
      .required("fpo_email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
    address: Yup.string().required("House Number is required"),
    street: Yup.string().required("Street/Area is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    pinCode: Yup.string()
      .matches(/^[0-9]+$/, "Invalid. Pin Code must be only digits")
      .length(6, "Pin Code must be exactly 6 digits")
      .required("Pin Code is required"),
    cin: Yup.string().required("CIN is required"),
    gst_number: Yup.string()
      .required("GST Number is required")
      .min(12, "GST Number must be 12 characters long")
      .max(12, "GST Number must be 15 characters long"),
  });

  const handleSubmit = useCallback(
    (
      values: FPOObj,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      setIsSubmitting(true);
      setCommonError("");

      const obj = {
        fpo_name: values.fpo_name,
        fpo_number: values.fpo_number,
        fpo_email: values.fpo_email,
        password: values.password,
        cin: values.cin,
        gst_number: values.gst_number,
        address: values.address,
        city: values.city,
        state: values.state,
        pincode: values.pinCode,
      };
      axiosInstance
        .post(`/fpo/create`, obj, {
          headers: {
            ContentType: "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setIsSubmitting(false);
            setSubmitting(false);
            dispatch(
              addToast({
                kind: SUCCESS,
                msg: "FPO Added Successfully!",
              })
            );
            queryClient.invalidateQueries(["getAllFpos"]);
            setShowForm(false);
            dispatch(hideModal());
          }
        })
        .catch((error: AxiosError) => {
          setIsSubmitting(false);
          setSubmitting(false);

          if (error.response) {
            const { msg } = error.response.data as { msg: string };
            switch (error.response.status) {
              case 400:
              case 403:
              case 500:
                setCommonError(msg);
                break;
              case 404:
                setCommonError("No data found for the given FPO.");
                break;
              default:
                setCommonError("Oops, something went wrong");
                break;
            }
          } else if (error.request) {
            setCommonError("Oops, something went wrong");
          } else {
            setCommonError(`Error: ${error.message}`);
          }
        });
    },
    [dispatch, queryClient]
  );

  return (
    <>
      {showForm && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form
            className="max-w-sm flex flex-col justify-center items-center bg-white px-6 py-5 shadow"
            style={{ minWidth: "360px" }}
          >
            <img src={logo} alt="logo" height={100} width={100} />

            <Input
              label="FPO Name"
              id="fpo_name"
              name="fpo_name"
              type="text"
              placeholder="Enter Name"
              className="block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
            <Input
              label="Phone Number"
              id="fpo_number"
              name="fpo_number"
              type="text"
              placeholder="91 xxxxx xxxxx"
              className="block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
            <Input
              label="email"
              id="fpo_email"
              name="fpo_email"
              type="email"
              placeholder="Example@gmail.com"
              className="block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
            <Input
              label="Password"
              id="password"
              name="password"
              type="password"
              placeholder="Password Assigned By FPO"
              className="block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />

            <div className="mb-0 w-full">
              <label className="block text-sm font-semibold text-gray-800">
                Address
              </label>
              <Input
                label="House Number"
                id="address"
                name="address"
                type="text"
                placeholder="Address"
                className="block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <Input
                label="Street/Area"
                id="street"
                name="street"
                type="text"
                placeholder="Street/Area"
                className="block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <div className="flex mb-4 w-full space-x-4">
                <Input
                  label="City"
                  id="city"
                  name="city"
                  type="text"
                  placeholder="City"
                  className="block flex-1 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
                <div className="w-full">
                  <Select
                    options={stateArray}
                    label="State"
                    id="state"
                    name="state"
                  />
                </div>
                <Input
                  label="Pin Code"
                  id="pinCode"
                  name="pinCode"
                  type="text"
                  placeholder="Pin Code"
                  className="block flex-1 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>

            <Input
              label="CIN"
              id="cin"
              name="cin"
              type="text"
              placeholder="ABC"
              className="block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />

            <Input
              label="GST Number"
              id="gst_number"
              name="gst_number"
              type="text"
              placeholder="xxxx xxxxx"
              className=" block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />

            {commonError && (
              <div className="common-error mb-2 text-center">
                <ErrorBox msg={commonError} />
              </div>
            )}

            <div className="buttons flex flex-col items-center w-full justify-center my-4 mb-6">
              <SubmitBtn
                text="Add FPO"
                isSubmitting={isSubmitting}
                classes="text-sm"
              />
            </div>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default FPOOnboardedForm;
